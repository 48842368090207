<template>
  <div class="home">
    <div class="content-1">
      <div class="content-text" data-aos="fade-left">
        <h3>Hódítsd meg a szervert!</h3>
        <p>Csatlakozz a már több mint másfél éve futó szerverünkhöz és kezdd meg nálunk  a második életed!</p>
        <a href="fivem://connect/37.221.209.118:30120"><i class="bi bi-arrow-right"></i></a>
      </div>
      <div class="triangle"></div>
    </div>
    <div class="content-2">
      <div class="content-text" data-aos="fade-right">
        <h3>Szerver Információk!</h3>
        <p>Néhány friss statisztika közvetlen a FiveM szerverünkről</p>
      </div>
      <div class="content-counters">
        <div class="counter">
          <h1 id="regply">75421</h1>
          <p>Regisztrált játékos</p>
        </div>
        <div class="counter">
          <h1 id="onlineply">34</h1>
          <p>Jelenleg online játékos</p>
        </div>
        <div class="counter">
          <h1 id="admincount">11</h1>
          <p>Adminisztrátor</p>
        </div>
      </div>
    </div>
    <div class="content-3">
      <div class="content-text" data-aos="fade-left">
        <h3>Csatlakozz a discordunkra!</h3>
        <p>Lépj be discord szerverünkre, ahol pontos leírást adunk arról hogy csatlakozhatsz fel a szerverre, illetve minden fontos infót itt fogsz megtalálni</p>
        <a href="http://dc.blackcityrp.hu/"><i class="bi bi-arrow-right"></i></a>
      </div>
      <div class="triangle"></div>
    </div>
  </div>
</template>

<script>
import { CountUp } from 'countup.js';

export default {
  name: 'HomeView',
  components: {
  }, 
  async mounted() {
    const response = await fetch("/api/public/infos")
    if (!response || !response.ok) return 
    const data = await response.json()
    if (!data) return
    const regplayers = new CountUp("regply", data.usercount, { enableScrollSpy: true });
    regplayers.handleScroll();
    const onlineplayers = new CountUp("onlineply", data.playercount, { enableScrollSpy: true });
    onlineplayers.handleScroll();
    const admins = new CountUp("admincount", data.admincount, { enableScrollSpy: true });
    admins.handleScroll();
  }
}
</script>

<style>
.home {
  background-color: #1F1F1F;
  height: 100%;
}
.content-1 {
  position: relative;
  height: 40vw;
  background:
  linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.803)),
    url('../assets/bg1.webp');
  background-size: cover;
  animation: shrink 9s infinite alternate;
}
.content-1 .triangle {
  z-index: 10;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-bottom: 8vw solid #1F1F1F;
  border-left: 100vw solid transparent;
}
.content-1 .content-text {
  color: white;
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  right: 10vw;
  height: 80%;
  text-align: right;
}
.content-text {
  font-size: 130%;
  max-width: 30vw;
}
.content-text a {
  color: white;
  font-size: 4vw;
  transition: transform .7s ease-in-out;
  width: auto;
}
.content-text a:hover {
  transform: translateX(5vw);
}
.content-2 {
  position: relative;
  height: 30vw;
}
.content-2 .content-text {
  position: absolute;
  text-align: left;
  left: 10vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  transform: translateY(-50%);
}
.content-counters {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  right: 10vw;
  top: 50%;
  width: 40vw;
  transform: translateY(-50%);
}
.content-counters .counter p {
  max-width: 10vw;
}
.content-3 {
  position: relative;
  height: 40vw;
  background:
  linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.803)),
    url('../assets/bg2.webp');
  background-size: cover;
  animation: shrink 9s infinite alternate;
}
.content-3 .triangle {
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 8vw solid #1F1F1F;
  border-left: 100vw solid transparent;
}
.content-3 .content-text {
  color: white;
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  right: 10vw;
  bottom: 0;
  height: 80%;
  text-align: right;
}


@keyframes shrink {
  0% {
    background-size: 100% auto;
  }
  100% {
    background-size: 110% auto;
  }
}

@keyframes shrinkhieght {
  0% {
    background-size: auto 100%;
  }
  100% {
    background-size: auto 110%;
  }
}

@media (min-width: 860px) and (max-width: 1150px) {
  .content-text {
    font-size: 100%;
  }
}

@media (min-width: 650px) and (max-width: 861px) {
  .content-text {
    font-size: 90%;
    max-width: 50vw;
  }
  .content-2 {
    position: relative;
    height: 80vw;
  }
  .content-2 .content-text {
    display: block;
    position: relative;
    text-align: left;
    left: 10vw;
  }
  .content-counters {
    height: 60vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    bottom: 3vw;
    left: 50vw;
    right: unset;
    top: unset;
    width: 40vw;
    transform: translateX(-50%);
  }
  .content-counters .counter p {
    max-width: 30vw;
  }
}

@media  (max-width: 651px) {
  .content-text {
    font-size: 90%;
    max-width: 80vw;
  }
  .content-text a {
    font-size: 180%;
  }
  .content-1 {
    height: 90vw;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.803)),
      url('../assets/bg1.webp');
    animation: shrinkhieght 9s infinite alternate;
  }
  .content-2 {
    position: relative;
    height: 120vw;
  }
  .content-2 .content-text {
    display: block;
    position: relative;
    text-align: left;
    left: 10vw;
  }
  .content-3 {
    height: 110vw;
    background:
    linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.803)),
      url('../assets/bg2.webp');
    animation: shrinkhieght 9s infinite alternate;
  }
  .content-counters {
    height: 70vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    bottom: 3vw;
    left: 50vw;
    right: unset;
    top: unset;
    width: 40vw;
    transform: translateX(-50%);
    font-size: 70%;
  }
  .content-counters .counter p {
    max-width: 30vw;
  }
}
</style>
