<template>
    <div class="site-cont" v-html="sitehtml">
    </div>
</template>
  
<script>
  export default {
    name: 'ManagerView',
    data() {
        return {
          sitehtml : "<h2>A keresett oldal nem található</h2>"
        }
    },
    async mounted() {
      if (!this.$route.params.site) return;
      const sitename = this.$route.params.site
      try {
        const response = await fetch("/api/sitedata/"+sitename)
        const sitecontent = await response.text()
        this.sitehtml = sitecontent
      } catch {
        this.sitehtml = "<h2>A keresett oldal nem található</h2>" 
      }
    }
  }
</script>
  
<style>
</style>